import {
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------------------------------------

// No Save Required Modal Component
function NoSaveRequiredModal({ handleClose, open }) {
    return (
        <Dialog open={open} closeAfterTransition={false}>
            <Box display="flex" alignItems="center" sx={{ pt: 1, px: 3 }}>
                <Warning
                    fontSize="large"
                    sx={{
                        path: {
                            color: (theme) => theme.palette.primary.main,
                        },
                    }}
                />
                <DialogTitle color="primary" sx={{ fontSize: "1.25em", fontWeight: 600 }}>
                    변경 사항 없음
                </DialogTitle>
            </Box>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <DialogContentText
                    sx={{
                        maxWidth: "25em",
                        span: {
                            color: (theme) => theme.palette.grey[700],
                        },
                    }}
                >
                    저장할 변경 사항이 존재하지 않아요.
                </DialogContentText>
                <DialogContentText
                    sx={{
                        maxWidth: "25em",
                        span: {
                            color: (theme) => theme.palette.grey[700],
                        },
                    }}
                >
                    변경한 항목의 내용이 <b>제대로 입력되었는지 다시 확인</b>해 주세요.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", pb: 3, px: 3 }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleClose}
                    sx={{
                        fontWeight: 500,
                        fontSize: "1em",
                        width: "40%",
                        maxWidth: "150px",
                    }}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// ----------------------------------------------------------------------------------------------------

NoSaveRequiredModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

// ----------------------------------------------------------------------------------------------------

export default NoSaveRequiredModal;
