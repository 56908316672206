import { create } from "zustand";
import { devtools } from "zustand/middleware";

// ----------------------------------------------------------------------------------------------------

// Project Storage
const useProjectStore = create(
    devtools((set) => ({
        selectedProject: "",
        projectMap: { companyWorkspace: "회사 작업영역" },
        setSelectedProject: (newSelectedProject) =>
            set(() => ({ selectedProject: newSelectedProject })),
        addProjectMap: (newProjects) =>
            set((state) => {
                const newProjectMap = { ...state.projectMap, ...newProjects };
                return { projectMap: newProjectMap };
            }),
        resetProjectMap: () => set(() => ({ projectMap: { companyWorkspace: "회사 작업영역" } })),
    })),
);

// ----------------------------------------------------------------------------------------------------

// Table Storage
const useTableStore = create(
    devtools((set) => ({
        selectedTable: "",
        tableList: [],
        setSelectedTable: (newSelectedTable) => set(() => ({ selectedTable: newSelectedTable })),
        setTableList: (newTableList) => set(() => ({ tableList: newTableList })),
    })),
);

// ----------------------------------------------------------------------------------------------------

// Data Storage
const useDataStore = create(
    devtools((set, get) => ({
        deletedLineItem: {},
        updatedLineItem: {},
        isSaved: false,
        isModified: false,
        originData: [],
        rowSelectionModel: [],
        rowData: {},
        saveData: [],
        sheetData: [],
        sheetDataLabel: {
            id: "아이디",
            _bp_lineitems: "라인 아이템",
            record_no: "레코드 번호",
            uuu_creation_date: "생성일시",
            uuu_last_update_date: "마지막 수정일시",
            uuu_record_last_update_date: "마지막 수정일시",
            creator_id: "생성자",
            status: "상태",
            tab_id: "탭 아이디",
            uuu_tab_id: "탭 이름",
            LineAutoSeq: "라인 아이템 아이디",
            li_num: "라인 아이템 번호",
            short_desc: "요약",
        },
        updatedRecord: new Set(),
        setUpdatedLineItem: (newUpdatedLineItem) =>
            set((state) => {
                const mergedUpdatedLineItem = { ...state.updatedLineItem };

                Object.keys(newUpdatedLineItem).forEach((recordNo) => {
                    if (mergedUpdatedLineItem[recordNo]) {
                        mergedUpdatedLineItem[recordNo] = [
                            ...mergedUpdatedLineItem[recordNo],
                            ...newUpdatedLineItem[recordNo],
                        ];
                    } else {
                        mergedUpdatedLineItem[recordNo] = newUpdatedLineItem[recordNo];
                    }
                });

                return { updatedLineItem: mergedUpdatedLineItem };
            }),
        setDeletedLineItem: (newDeletedLineItem) =>
            set((state) => {
                const mergedDeletedLineItem = { ...state.deletedLineItem };

                Object.keys(newDeletedLineItem).forEach((recordNo) => {
                    if (mergedDeletedLineItem[recordNo]) {
                        mergedDeletedLineItem[recordNo] = [
                            ...mergedDeletedLineItem[recordNo],
                            ...newDeletedLineItem[recordNo],
                        ];
                    } else {
                        mergedDeletedLineItem[recordNo] = newDeletedLineItem[recordNo];
                    }
                });

                return { deletedLineItem: mergedDeletedLineItem };
            }),
        setRowSelectionModel: (newRowSelectionModel) =>
            set(() => ({ rowSelectionModel: newRowSelectionModel })),
        setIsSaved: (newIsSaved) => set(() => ({ isSaved: newIsSaved })),
        setIsModified: (newIsModified) => set(() => ({ isModified: newIsModified })),
        setOriginData: (newOriginData) => set(() => ({ originData: newOriginData })),
        setRowData: (newRowData) => set(() => ({ rowData: newRowData })),
        changeRow: (newRowData) =>
            set((state) => {
                const targetIndex = state.sheetData.findIndex(
                    (record) => record.record_no === newRowData.record_no,
                );
                const newSheetData = [...state.sheetData];
                newSheetData[targetIndex] = newRowData;
                return { sheetData: newSheetData };
            }),
        getSheetData: () => get().sheetData,
        setSheetData: (newSheetData) => set(() => ({ sheetData: newSheetData })),
        setSaveData: (newSaveData) => set(() => ({ saveData: newSaveData })),
        setSheetDataLabel: (newLabelList) =>
            set((state) => {
                const newSheetDataLabel = { ...state.sheetDataLabel, ...newLabelList };
                return { sheetDataLabel: newSheetDataLabel };
            }),
        addUpdatedRecord: (newRecord) =>
            set((state) => {
                const updatedRecord = new Set(state.updatedRecord);
                updatedRecord.add(newRecord);
                return { updatedRecord };
            }),
        resetUpdatedRecord: () => set(() => ({ updatedRecord: new Set() })),
        resetDataTransfer: () =>
            set(() => ({
                deletedLineItem: {},
                updatedLineItem: {},
                isSaved: false,
                isRecordModified: false,
                isLineItemModified: false,
                rowSelectionModel: [],
                updatedRecord: new Set(),
            })),
        resetDataStore: () =>
            set(() => ({
                deletedLineItem: {},
                updatedLineItem: {},
                isSaved: false,
                isRecordModified: false,
                isLineItemModified: false,
                originData: [],
                rowSelectionModel: [],
                rowData: {},
                saveData: [],
                sheetData: [],
                updatedRecord: new Set(),
            })),
    })),
);

// ----------------------------------------------------------------------------------------------------

export { useProjectStore, useTableStore, useDataStore };
