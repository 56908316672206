import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#58A4B0",
            light: "#82BBC4",
            extraLight: "#E3F0F2",
            dark: "#41818B",
            extraDark: "#152629",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#FF7075",
            light: "#FFB6B9",
            extraLight: "#FFD6D8",
            dark: "#CC0007",
            extraDark: "#8F0005",
            contrastText: "#FFFFFF",
        },
    },
    typography: {
        fontFamily: "Pretendard",
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: "600",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    ":hover": {
                        backgroundColor: "#E3F0F2",
                    },
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        width: "0.5rem",
                        height: "0.5rem",
                    },
                    "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: 10,
                        backgroundColor: "rgba(21, 38, 41, 0.33)",
                        minHeight: 24,
                    },
                },
            },
        },
    },
});

export default theme;
