import { createBrowserRouter, Navigate } from "react-router-dom";
import Sheet from "@pages/sheet/Sheet";
import App from "../App";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <Navigate to="/sheet" replace />,
            },
            {
                path: "sheet",
                element: <Sheet />,
            },
        ],
    },
]);

export default router;
