import { AddCircleOutline, CheckCircle, Cancel, Edit } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import {
    DataGridPro,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import gridLocaleConfig from "@configs/GridLocaleConfig";
import { LineItemModal } from "@components/modal";
import PropTypes from "prop-types";
import { RecordGridToolbar } from "@components/toolbar";
import { useDataStore, useProjectStore, useTableStore } from "@stores/useSheetStore";
import { useMemo, useState } from "react";

// ----------------------------------------------------------------------------------------------------

// Record Grid Component
function RecordGrid({ isLoading }) {
    const [isLineItemModalOpen, setIsLineItemModalOpen] = useState(false);
    const [rowModesModel, setRowModesModel] = useState({});
    const { selectedProject } = useProjectStore();
    const { selectedTable } = useTableStore();
    const { sheetData, setIsModified, setRowData, setSheetData, sheetDataLabel } = useDataStore();
    const apiRef = useGridApiRef();

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleLineItemClick = (data) => async () => {
        await new Promise((resolve) => {
            setRowData(data);
            resolve();
        });
        setIsLineItemModalOpen(true);
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        setIsModified(true);
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = sheetData.find((row) => row.id === id);
        if (!editedRow.record_no) {
            setSheetData(sheetData.filter((row) => row.id !== id));
        }
    };

    const columns = useMemo(() => {
        if (selectedProject && selectedTable && sheetData.length > 0) {
            const dataColumns = Object.keys(sheetData[0])
                .filter((key) => key !== "_bp_lineitems")
                .map((key) => ({
                    field: key,
                    headerName: sheetDataLabel[key] || key,
                    width: 150,
                    maxWidth: 300,
                    editable: key !== "record_no",
                }));

            const actionsColumn = {
                field: "actions",
                type: "actions",
                width: 100,
                cellClassName: "actions",
                resizable: false,
                getActions: ({ id, row }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        return [
                            <Tooltip title="확인" arrow>
                                <GridActionsCellItem
                                    icon={
                                        <CheckCircle
                                            sx={{
                                                path: {
                                                    color: (theme) => theme.palette.primary.main,
                                                },
                                            }}
                                        />
                                    }
                                    label="save"
                                    onClick={handleSaveClick(id)}
                                />
                            </Tooltip>,
                            <Tooltip title="취소" arrow>
                                <GridActionsCellItem
                                    icon={
                                        <Cancel
                                            sx={{
                                                path: {
                                                    color: (theme) => theme.palette.primary.main,
                                                },
                                            }}
                                        />
                                    }
                                    label="cancel"
                                    onClick={handleCancelClick(id)}
                                />
                            </Tooltip>,
                        ];
                    }

                    return [
                        <Tooltip title="수정" arrow>
                            <GridActionsCellItem
                                icon={
                                    <Edit
                                        sx={{
                                            path: {
                                                color: (theme) => theme.palette.primary.main,
                                            },
                                        }}
                                    />
                                }
                                label="modify"
                                onClick={handleEditClick(id)}
                            />
                        </Tooltip>,
                        Array.isArray(row._bp_lineitems) && row._bp_lineitems.length > 0 ? (
                            <Tooltip title="라인 아이템 보기" arrow>
                                <GridActionsCellItem
                                    icon={
                                        <AddCircleOutline
                                            sx={{
                                                path: {
                                                    color: (theme) => theme.palette.primary.main,
                                                },
                                            }}
                                        />
                                    }
                                    label="lineitem"
                                    onClick={handleLineItemClick(row)}
                                />
                            </Tooltip>
                        ) : (
                            <GridActionsCellItem
                                disabled
                                icon={
                                    <AddCircleOutline
                                        sx={{
                                            path: {
                                                color: (theme) => theme.palette.action.disabled,
                                            },
                                        }}
                                    />
                                }
                                label="lineitem"
                            />
                        ),
                    ];
                },
            };

            return [...dataColumns, actionsColumn];
        }
        return [];
    }, [sheetData, rowModesModel]);

    const resizeColumns = () => {
        const autoSizeOptions = {
            includeHeaders: true,
            includeOutliers: true,
            outliersFactor: 1,
            expand: true,
        };
        apiRef.current.autosizeColumns(autoSizeOptions);
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            Object.assign(event, { defaultMuiPrevented: true });
        }
    };

    const processRowUpdate = (newRow) => {
        setSheetData(sheetData.map((row) => (row.id === newRow.id ? newRow : row)));
        return newRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                overflow: "scroll",
                "& .MuiDataGrid-columnHeader, & .MuiDataGrid-filler": {
                    backgroundColor: (theme) => theme.palette.primary.extraLight,
                },
                " &. MuiDataGrid-columnHeaderTitleContainer": {
                    backgroundColor: (theme) => theme.palette.primary.extraLight,
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: 800,
                    fontSize: "1.025rem",
                },
                "& .MuiDataGrid-columnSeparator": {
                    rect: {
                        color: (theme) => theme.palette.primary.main,
                    },
                },
            }}
        >
            <DataGridPro
                apiRef={apiRef}
                columnBufferPx={3000}
                columns={columns}
                componentsProps={{
                    pagination: {
                        labelRowsPerPage: "페이지 당 확인할 데이터 수",
                    },
                }}
                editMode="row"
                getRowId={(row) => row.id}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 10 },
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                    pinnedColumns: {
                        right: ["actions"],
                    },
                }}
                loading={isLoading}
                localeText={gridLocaleConfig}
                onRowEditStop={handleRowEditStop}
                onRowModesModelChange={handleRowModesModelChange}
                pageSizeOptions={[10, 25, 100]}
                pagination
                processRowUpdate={processRowUpdate}
                rowModesModel={rowModesModel}
                rows={sheetData}
                slotProps={{
                    toolbar: { setRowModesModel, resizeColumns },
                }}
                slots={{
                    toolbar: RecordGridToolbar,
                }}
            />
            <LineItemModal
                handleClose={() => {
                    setIsLineItemModalOpen(false);
                    setRowData({});
                }}
                open={isLineItemModalOpen}
            />
        </Box>
    );
}

// ----------------------------------------------------------------------------------------------------

RecordGrid.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

// ----------------------------------------------------------------------------------------------------

export default RecordGrid;
