import { Add, CompareArrows } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { GridRowModes, GridToolbarContainer } from "@mui/x-data-grid-pro";
import generateRandomId from "@utils/format";
import PropTypes from "prop-types";
import { ServiceToolbar } from "@components/toolbar";
import { useDataStore } from "@stores/useSheetStore";

function RecordGridToolbar({ setRowModesModel, resizeColumns }) {
    const { sheetData, setSheetData } = useDataStore();
    const handleClick = () => {
        const id = generateRandomId();
        const emptyRow = Object.keys(sheetData[0] || {})
            .filter((key) => key !== "id" && key !== "_bp_lineitems")
            .reduce(
                (acc, key) => {
                    acc[key] = "";
                    return acc;
                },
                { id },
            );

        setSheetData([emptyRow, ...sheetData]);
        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.Edit },
        }));
    };

    return (
        <GridToolbarContainer
            sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: 1.5,
                paddingBottom: 1,
                px: 3,
                backgroundColor: (theme) => theme.palette.primary.extraLight,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: 1,
                }}
            >
                <Button
                    color="primary"
                    variant="contained"
                    disabled={sheetData.length === 0}
                    startIcon={
                        <Add
                            sx={{
                                path: {
                                    color: (theme) =>
                                        sheetData.length === 0
                                            ? theme.palette.action.disabled
                                            : "white",
                                },
                            }}
                        />
                    }
                    onClick={handleClick}
                    sx={{
                        fontWeight: 700,
                        fontSize: "1rem",
                        borderRadius: "10px",
                        minWidth: "100px",
                        height: "40px",
                    }}
                >
                    추가
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={sheetData.length === 0}
                    startIcon={
                        <CompareArrows
                            sx={{
                                path: {
                                    color: (theme) =>
                                        sheetData.length === 0
                                            ? theme.palette.action.disabled
                                            : "white",
                                },
                            }}
                        />
                    }
                    onClick={resizeColumns}
                    sx={{
                        fontWeight: 700,
                        fontSize: "1rem",
                        borderRadius: "10px",
                        minWidth: "180px",
                        height: "40px",
                    }}
                >
                    열 너비 자동 조정
                </Button>
            </Box>
            <ServiceToolbar />
        </GridToolbarContainer>
    );
}

RecordGridToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    resizeColumns: PropTypes.func.isRequired,
};

export default RecordGridToolbar;
