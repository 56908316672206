import { CssBaseline, Stack, ThemeProvider } from "@mui/material";
import globalTheme from "@styles/theme";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";

const MUI_X_LICENSE_KEY = process.env.REACT_APP_MUI_X_LICENSE_KEY;
const AppContainer = styled(Stack)(({ theme }) => ({
    minHeight: "100vh",
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(4),
    },
    "&::before": {
        content: '""',
        display: "block",
        position: "absolute",
        zIndex: -1,
        inset: 0,
        backgroundImage:
            "radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))",
        backgroundRepeat: "no-repeat",
    },
}));

LicenseInfo.setLicenseKey(MUI_X_LICENSE_KEY);

export default function App() {
    return (
        <ThemeProvider theme={globalTheme}>
            <CssBaseline />
            <AppContainer direction="row" justifyContent="center" gap="2rem">
                <Outlet />
            </AppContainer>
        </ThemeProvider>
    );
}
