import { Box, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { LineItemGrid } from "@components/grid";
import { Close, PostAdd } from "@mui/icons-material";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------------------------------------

// Line Item Modal Component
function LineItemModal({ handleClose, open }) {
    return (
        <Dialog fullWidth maxWidth="xl" open={open} closeAfterTransition={false}>
            <Box display="flex" alignItems="center" sx={{ pt: 1, px: 3 }}>
                <PostAdd
                    fontSize="large"
                    sx={{
                        path: {
                            color: (theme) => theme.palette.primary.main,
                        },
                    }}
                />
                <DialogTitle color="primary" sx={{ fontSize: "1.25em", fontWeight: 600 }}>
                    라인 아이템 편집기
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                >
                    <Close />
                </IconButton>
            </Box>
            <DialogContent
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    p: 0,
                }}
            >
                <LineItemGrid />
            </DialogContent>
        </Dialog>
    );
}

// ----------------------------------------------------------------------------------------------------

LineItemModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

// ----------------------------------------------------------------------------------------------------

export default LineItemModal;
