import { Box, CircularProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------------------------------------

// Progress Circle Component
function ProgressCircle({ type }) {
    return (
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 3,
                backgroundColor: "rgba(227, 240, 242, 0.9)",
                zIndex: 1300,
            }}
        >
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#82BBC4" />
                        <stop offset="100%" stopColor="#58A4B0" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress
                sx={{
                    "svg circle": { stroke: "url(#my_gradient)", strokeLinecap: "round" },
                    animationDuration: "5000ms",
                }}
                size={50}
                thickness={5}
            />
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 0.5,
                }}
            >
                <Typography
                    sx={{
                        fontSize: "1.5rem",
                        fontWeight: 700,
                        color: (theme) => theme.palette.primary.main,
                    }}
                >
                    수정한 데이터를 {type === "save" ? "저장" : "유니파이어로 전송"} 중이에요!
                </Typography>
                <Typography sx={{ fontSize: "1rem", color: (theme) => theme.palette.grey[700] }}>
                    <br />
                    {type === "save" ? "저장" : "유니파이어로 전송"}이 완료될 때까지{" "}
                    <b>잠시만 기다려주세요.</b>
                </Typography>
                <Typography sx={{ fontSize: "1rem", color: (theme) => theme.palette.grey[700] }}>
                    데이터의 크기가 클수록 시간이 <b>오래 걸릴 수 있어요.</b>
                </Typography>
            </Box>
        </Box>
    );
}

// ----------------------------------------------------------------------------------------------------

ProgressCircle.propTypes = {
    type: PropTypes.string.isRequired,
};

// ----------------------------------------------------------------------------------------------------

export default ProgressCircle;
