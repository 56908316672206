import {
    DataGridPro,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Box, Stack, Tooltip } from "@mui/material";
import { CheckCircle, Cancel, Delete, Edit } from "@mui/icons-material";
import gridLocaleConfig from "@configs/GridLocaleConfig";
import { useEffect, useMemo, useState } from "react";
import { useDataStore, useProjectStore, useTableStore } from "@stores/useSheetStore";
import { LineItemGridToolbar } from "@components/toolbar";

// ----------------------------------------------------------------------------------------------------

// Line Item Grid Component
function LineItemGrid() {
    const [isLoading, setIsLoading] = useState(true);
    const [rowModesModel, setRowModesModel] = useState({});
    const { selectedProject } = useProjectStore();
    const { selectedTable } = useTableStore();
    const {
        rowData,
        sheetDataLabel,
        rowSelectionModel,
        setIsModified,
        setRowData,
        setRowSelectionModel,
    } = useDataStore();
    const apiRef = useGridApiRef();

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            Object.assign(event, { defaultMuiPrevented: true });
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleDeleteClick = (id) => () => {
        setIsLoading(true);
        apiRef.current.updateRows([{ id, _action: "delete" }]);

        const newRowData = {
            ...rowData,
            _bp_lineitems: rowData._bp_lineitems.filter((item) => item.id !== id),
        };

        setRowData(newRowData);
        setIsModified(true);
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        setIsModified(true);
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedItem = rowData._bp_lineitems.find((item) => item.id === id);

        if (!editedItem.LineAutoSeq) {
            const newRowData = {
                ...rowData,
                _bp_lineitems: rowData._bp_lineitems.filter((item) => item.id !== id),
            };

            setRowData(newRowData);
        }
    };

    const columns = useMemo(() => {
        if (
            selectedProject &&
            selectedTable &&
            Array.isArray(rowData._bp_lineitems) &&
            rowData._bp_lineitems.length > 0
        ) {
            const dataColumns = Object.keys(rowData._bp_lineitems[0]).map((key) => ({
                field: key,
                headerName: sheetDataLabel[key] || key,
                width: 150,
                maxWidth: 300,
                editable: key !== "LineAutoSeq",
            }));

            const actionsColumn = {
                field: "actions",
                type: "actions",
                width: 100,
                cellClassName: "actions",
                resizable: false,
                getActions: ({ id }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        return [
                            <Tooltip title="확인" arrow>
                                <GridActionsCellItem
                                    icon={
                                        <CheckCircle
                                            sx={{
                                                path: {
                                                    color: (theme) => theme.palette.primary.main,
                                                },
                                            }}
                                        />
                                    }
                                    label="save"
                                    onClick={handleSaveClick(id)}
                                />
                            </Tooltip>,
                            <Tooltip title="취소" arrow>
                                <GridActionsCellItem
                                    icon={
                                        <Cancel
                                            sx={{
                                                path: {
                                                    color: (theme) => theme.palette.primary.main,
                                                },
                                            }}
                                        />
                                    }
                                    label="cancel"
                                    onClick={handleCancelClick(id)}
                                />
                            </Tooltip>,
                        ];
                    }

                    return [
                        <Tooltip title="수정" arrow>
                            <GridActionsCellItem
                                icon={
                                    <Edit
                                        sx={{
                                            path: {
                                                color: (theme) => theme.palette.primary.main,
                                            },
                                        }}
                                    />
                                }
                                label="modify"
                                onClick={handleEditClick(id)}
                            />
                        </Tooltip>,
                        <Tooltip title="삭제" arrow>
                            <GridActionsCellItem
                                icon={
                                    <Delete
                                        sx={{
                                            path: {
                                                color: (theme) => theme.palette.error.main,
                                            },
                                        }}
                                    />
                                }
                                label="delete"
                                onClick={handleDeleteClick(id)}
                            />
                        </Tooltip>,
                    ];
                },
            };

            return [...dataColumns, actionsColumn];
        }
        return [];
    }, [rowData, rowModesModel]);

    const resizeColumns = () => {
        const autoSizeOptions = {
            includeHeaders: true,
            includeOutliers: true,
            outliersFactor: 1,
            expand: true,
        };
        apiRef.current.autosizeColumns(autoSizeOptions);
    };

    const processRowUpdate = (newItem) => {
        const newRowData = {
            ...rowData,
            _bp_lineitems: rowData._bp_lineitems.map((item) =>
                item.id === newItem.id ? newItem : item,
            ),
        };
        setRowData(newRowData);
        return newItem;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    useEffect(() => {
        setIsLoading(false);
    }, [rowData]);

    return (
        <Stack direction="column" sx={{ display: "flex", height: "100%" }}>
            <Box
                sx={{
                    width: "100%",
                    height: "calc(100vh - 150px)",
                    "& .MuiDataGrid-columnHeader, & .MuiDataGrid-filler": {
                        backgroundColor: (theme) => theme.palette.primary.extraLight,
                    },
                    " &. MuiDataGrid-columnHeaderTitleContainer": {
                        backgroundColor: (theme) => theme.palette.primary.extraLight,
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        color: (theme) => theme.palette.primary.main,
                        fontWeight: 800,
                        fontSize: "1.025rem",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        rect: {
                            color: (theme) => theme.palette.primary.main,
                        },
                    },
                }}
            >
                <DataGridPro
                    apiRef={apiRef}
                    checkboxSelection
                    columnBufferPx={3000}
                    columns={columns}
                    density="compact"
                    editMode="row"
                    getRowId={(row) => row.id}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10 },
                        },
                        columns: {
                            columnVisibilityModel: {
                                id: false,
                            },
                        },
                        pinnedColumns: {
                            right: ["actions"],
                        },
                    }}
                    loading={isLoading}
                    localeText={gridLocaleConfig}
                    onRowEditStop={handleRowEditStop}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    pageSizeOptions={[10, 25, 100]}
                    pagination
                    processRowUpdate={processRowUpdate}
                    rows={rowData._bp_lineitems || []}
                    rowModesModel={rowModesModel}
                    rowSelectionModel={rowSelectionModel}
                    slotProps={{
                        toolbar: { setRowModesModel, resizeColumns },
                    }}
                    slots={{
                        toolbar: LineItemGridToolbar,
                    }}
                />
            </Box>
        </Stack>
    );
}

export default LineItemGrid;
