import { Box, Button, styled } from "@mui/material";
import { Download, Upload } from "@mui/icons-material";
import generateRandomId from "@utils/format";
import ProgressCircle from "@components/progress";
import { SaveModal, SuccessModal, NoSaveRequiredModal } from "@components/modal";
import { useProjectStore, useTableStore, useDataStore } from "@stores/useSheetStore";
import { updateTableData } from "@service/sheet.service";
import { useRef, useState } from "react";
import * as XLSX from "xlsx";
import sleep from "@utils/time";

// ----------------------------------------------------------------------------------------------------

// Visually Hidden Input Component
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

// ----------------------------------------------ㅍ------------------------------------------------------

// Service Toolbar Component
function ServiceToolbar() {
    const [openSaveModal, setOpenSaveModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openNoSaveRequiredModal, setOpenNoSaveRequiredModal] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [progressType, setProgressType] = useState("save");
    const [saveCount, setSaveCount] = useState([0, 0, 0, 0, 0]);
    const [successTitle, setSuccessTitle] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const { selectedProject } = useProjectStore();
    const { selectedTable } = useTableStore();
    const fileInputRef = useRef(null);
    const {
        addUpdatedRecord,
        changeRow,
        deletedLineItem,
        getSheetData,
        isModified,
        isSaved,
        resetDataTransfer,
        rowData,
        saveData,
        sheetData,
        sheetDataLabel,
        setDeletedLineItem,
        setIsSaved,
        setIsModified,
        setSaveData,
        setSheetData,
        setUpdatedLineItem,
        updatedLineItem,
        updatedRecord,
    } = useDataStore();

    const handleSuccessModalOpen = () => {
        setOpenSuccessModal(true);
    };

    const handleSuccessModalClose = () => {
        setOpenSuccessModal(false);
    };

    const handleSaveModalOpen = () => {
        setOpenSaveModal(true);
    };

    const handleSaveModalClose = async () => {
        setOpenSaveModal(false);
        await sleep(100);
        setSaveCount([0, 0, 0, 0, 0]);
    };

    const handleNoSaveRequiredModalOpen = () => {
        setOpenNoSaveRequiredModal(true);
    };

    const handleNoSaveRequiredModalClose = () => {
        setOpenNoSaveRequiredModal(false);
    };

    const checkDataChange = async () => {
        setProgressType("save");
        setIsProcessing(true);
        if (Object.keys(rowData).length > 0) {
            changeRow(rowData);
            await sleep(1000);
        }

        let recordAddCount = 0;
        let recordUpdateCount = 0;
        let itemAddCount = 0;
        let itemUpdateCount = 0;
        let itemDeleteCount = 0;

        getSheetData().forEach((newRecord) => {
            const targetIndex = saveData.findIndex(
                (record) => record.record_no === newRecord.record_no,
            );
            const targetRecord = targetIndex !== -1 ? saveData[targetIndex] : null;

            if (targetRecord) {
                let addCount = 0;
                let updateCount = 0;
                let deleteCount = 0;
                const newUpdatedLineItem = { [newRecord.record_no]: [] };
                const newDeletedLineItem = { [newRecord.record_no]: [] };
                const isRecordUpdated = Object.keys(newRecord).some((key) => {
                    if (key !== "id" && key !== "_bp_lineitems") {
                        return newRecord[key] !== targetRecord[key];
                    }
                    return false;
                });

                if (isRecordUpdated) {
                    recordUpdateCount += 1;
                    addUpdatedRecord(targetRecord.record_no);
                }

                // Add & Update Count for Line Items
                newRecord._bp_lineitems.forEach((item) => {
                    const existingItem = targetRecord._bp_lineitems.find(
                        (targetItem) => targetItem.LineAutoSeq === item.LineAutoSeq,
                    );
                    if (existingItem) {
                        const isUpdated = Object.keys(item)
                            .filter((key) => key !== "id")
                            .some((key) => item[key] !== existingItem[key]);
                        if (isUpdated) {
                            newUpdatedLineItem[newRecord.record_no].push(item.LineAutoSeq);
                            updateCount += 1;
                        }
                    } else {
                        addCount += 1;
                    }
                });

                if (updateCount > 0) {
                    setUpdatedLineItem(newUpdatedLineItem);
                }

                // Delete Count
                targetRecord._bp_lineitems.forEach((item) => {
                    const existingItem = newRecord._bp_lineitems.find(
                        (targetItem) => targetItem.LineAutoSeq === item.LineAutoSeq,
                    );
                    if (!existingItem) {
                        if (item.LineAutoSeq) {
                            newDeletedLineItem[newRecord.record_no].push(item.LineAutoSeq);
                        }
                        deleteCount += 1;
                    }
                });

                if (deleteCount > 0) {
                    setDeletedLineItem(newDeletedLineItem);
                }

                itemAddCount += addCount;
                itemUpdateCount += updateCount;
                itemDeleteCount += deleteCount;
            } else {
                recordAddCount += 1;
            }
        });
        setIsProcessing(false);
        if (
            recordAddCount > 0 ||
            recordUpdateCount > 0 ||
            itemAddCount > 0 ||
            itemUpdateCount > 0 ||
            itemDeleteCount > 0
        ) {
            setSaveCount([
                recordAddCount,
                recordUpdateCount,
                itemAddCount,
                itemUpdateCount,
                itemDeleteCount,
            ]);
            setSaveData(getSheetData());
            setIsSaved(true);
            handleSaveModalOpen();
        } else {
            handleNoSaveRequiredModalOpen();
        }
        setIsModified(false);
    };

    const patchUploadedData = async () => {
        setProgressType("transmission");
        setIsProcessing(true);

        const filteredSaveData = saveData.filter(
            (record) => updatedRecord.has(record.record_no) || !record.record_no,
        );

        const refinedSaveData = filteredSaveData.map((record) => {
            if (record._bp_lineitems) {
                const filteredLineItems = record._bp_lineitems.filter(
                    (item) =>
                        updatedLineItem[record.record_no]?.includes(item.LineAutoSeq) ||
                        !item.LineAutoSeq,
                );
                return {
                    ...record,
                    _bp_lineitems: filteredLineItems,
                };
            }
            return record;
        });

        await updateTableData({
            projectName: selectedProject,
            tableName: selectedTable,
            updatedData: refinedSaveData,
            deletedData: deletedLineItem,
        })
            .then((response) => {
                setIsProcessing(false);
                setSuccessTitle("데이터 업데이트 완료");
                setSuccessMessage(response.message);
                resetDataTransfer();
                handleSuccessModalOpen();
            })
            .catch((error) => {
                alert(error.data.message);
                setIsProcessing(false);
            });
    };

    const downloadXLSXFile = () => {
        const workbook = XLSX.utils.book_new();
        workbook.Props = {
            Title: selectedTable,
        };

        const headers = Object.keys(sheetData[0]).filter(
            (key) => key !== "_bp_lineitems" && key !== "id",
        );
        const labels = headers.map((header) => sheetDataLabel[header]);
        const recordSheetData = [headers, labels];
        sheetData.forEach((data) => {
            recordSheetData.push(headers.map((header) => data[header]));
        });

        const recordSheet = XLSX.utils.aoa_to_sheet(recordSheetData);

        const colWidths = headers.map((header, index) => {
            const maxLength = recordSheetData.reduce((max, row) => {
                const cellValue = row[index] ? row[index].toString() : "";
                return Math.max(max, cellValue.length);
            }, header.length);
            return { wch: maxLength + 2 };
        });

        recordSheet["!cols"] = colWidths;
        recordSheet["!rows"] = [{ hidden: true }];

        XLSX.utils.book_append_sheet(workbook, recordSheet, "Record Sheet");

        const lineHeaders = Object.keys(sheetData[0]._bp_lineitems[0] || {}).filter(
            (key) => key !== "id",
        );
        const lineLabels = lineHeaders.map((header) => sheetDataLabel[header]);
        const lineSheetData = [
            ["레코드 번호", ...lineHeaders, "삭제 여부"],
            ["레코드 번호", ...lineLabels, "삭제 여부"],
        ];

        sheetData.forEach((data) => {
            if (Array.isArray(data._bp_lineitems)) {
                data._bp_lineitems.forEach((lineItem) => {
                    const lineItemRow = [
                        data.record_no,
                        ...lineHeaders.map((header) => lineItem[header]),
                    ];
                    lineSheetData.push(lineItemRow);
                });
            }
        });

        const lineSheet = XLSX.utils.aoa_to_sheet(lineSheetData);
        const lineColWidths = [{ wch: 15 }];

        lineHeaders.forEach((header, index) => {
            const maxLength = lineSheetData.reduce((max, row) => {
                const cellValue = row[index + 1] ? row[index + 1].toString() : "";
                return Math.max(max, cellValue.length);
            }, header.length);
            lineColWidths.push({ wch: maxLength + 2 });
        });

        lineSheet["!cols"] = lineColWidths;
        lineSheet["!rows"] = [{ hidden: true }];

        XLSX.utils.book_append_sheet(workbook, lineSheet, "Line Sheet");

        XLSX.writeFile(workbook, `${selectedTable}.xlsx`);
    };

    // 임시
    const showErrorModal = (message) => {
        alert(message);
    };

    const validateResult = (result) => {
        // const requiredFields = ["레코드 번호"];

        console.log(result);
        const recordNumberSet = new Set();

        const originalColumns = Object.keys(sheetData[0]);
        const sheetColumns = Object.keys(result[0]);

        const invalidColumns = sheetColumns.filter((key) => {
            if (key === "_bp_lineitems") {
                return false;
            }
            return !originalColumns.includes(key);
        });

        if (invalidColumns.length > 0) {
            showErrorModal("레코드에 유효하지 않은 열이 있습니다.");
            return false;
        }

        for (let idx = 0; idx < result.length; idx += 1) {
            const recordNo = result[idx].record_no;

            if (recordNumberSet.has(recordNo)) {
                showErrorModal(`중복된 레코드 번호(${recordNo})가 존재합니다.`);
                return false;
            }

            if (Array.isArray(result[idx]._bp_lineitems) && result[idx]._bp_lineitems.length > 0) {
                const targetRecord = sheetData.find(
                    (row) => Array.isArray(row._bp_lineitems) && row._bp_lineitems.length > 0,
                );

                const originalItemColumns = Object.keys(targetRecord._bp_lineitems[0]);
                const itemColumns = Object.keys(result[idx]._bp_lineitems[0]);
                const invalidItemColumns = itemColumns.filter(
                    (itemKey) => !originalItemColumns.includes(itemKey),
                );

                if (invalidItemColumns.length > 0) {
                    showErrorModal("라인 아이템에 유효하지 않은 열이 있습니다.");
                    return false;
                }
            }

            recordNumberSet.add(recordNo);
        }

        return true;
    };

    const readXLSXFile = (file) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });

            const recordSheet = workbook.Sheets[workbook.SheetNames[0]];

            const recordJsonData = XLSX.utils.sheet_to_json(recordSheet, {
                header: 1,
                defval: null,
            });

            const recordHeaders = recordJsonData[0];
            const recordRows = recordJsonData.slice(2);

            const result = recordRows.map((row) => {
                const obj = {};
                recordHeaders.forEach((header, index) => {
                    obj[header] = row[index];
                });
                return obj;
            });

            const lineSheet = workbook.Sheets[workbook.SheetNames[1]];

            const lineJsonData = XLSX.utils.sheet_to_json(lineSheet, {
                header: 1,
                defval: null,
            });

            const lineHeaders = lineJsonData[0];
            const lineRows = lineJsonData.slice(2);

            const lineItems = lineRows.map((lineRow) => {
                const lineItemObj = {};
                lineHeaders.forEach((header, index) => {
                    lineItemObj[header] = lineRow[index];
                });
                return lineItemObj;
            });

            lineItems.forEach((lineItem) => {
                const recordNo = lineItem["레코드 번호"];
                const parentRecord = result.find((record) => record.record_no === recordNo);
                if (parentRecord) {
                    if (!parentRecord._bp_lineitems) {
                        parentRecord._bp_lineitems = [];
                    }
                    const { "레코드 번호": _, "삭제 여부": isDeleted, ...baseLineItem } = lineItem;
                    if (!isDeleted) {
                        parentRecord._bp_lineitems.push(baseLineItem);
                    }
                }
            });

            if (!validateResult(result)) {
                return;
            }

            const identifiedResult = result.map((row) => ({
                id: generateRandomId(),
                ...row,
                _bp_lineitems:
                    row._bp_lineitems?.map((item) => ({
                        id: generateRandomId(),
                        ...item,
                    })) || [],
            }));

            setSheetData(identifiedResult);
        };

        reader.readAsArrayBuffer(file);
    };

    return (
        <>
            {isProcessing && <ProgressCircle type={progressType} />}
            <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                    sx={{
                        fontWeight: 600,
                        fontSize: "1rem",
                        borderRadius: "50px",
                        minWidth: "80px",
                    }}
                    disabled={!isModified}
                    type="button"
                    variant="outlined"
                    onClick={checkDataChange}
                >
                    저장
                </Button>
                <Button
                    sx={{
                        fontWeight: 600,
                        fontSize: "1rem",
                        borderRadius: "50px",
                        minWidth: "180px",
                    }}
                    disabled={!isSaved}
                    type="button"
                    variant="contained"
                    onClick={patchUploadedData}
                >
                    유니파이어로 전송
                </Button>
                <Button
                    sx={{
                        fontWeight: 600,
                        fontSize: "1rem",
                        borderRadius: "10px",
                        minWidth: "150px",
                    }}
                    disabled={sheetData.length === 0}
                    type="button"
                    variant="outlined"
                    onClick={downloadXLSXFile}
                    startIcon={
                        <Download
                            sx={{
                                path: {
                                    color: (theme) =>
                                        sheetData.length === 0
                                            ? theme.palette.action.disabled
                                            : theme.palette.primary.main,
                                },
                            }}
                        />
                    }
                >
                    파일 다운로드
                </Button>
                <Button
                    sx={{
                        fontWeight: 600,
                        fontSize: "1rem",
                        borderRadius: "10px",
                        minWidth: "150px",
                    }}
                    disabled={sheetData.length === 0}
                    type="button"
                    component="label"
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={
                        <Upload
                            sx={{
                                path: {
                                    color: (theme) =>
                                        sheetData.length === 0
                                            ? theme.palette.action.disabled
                                            : theme.palette.primary.main,
                                },
                            }}
                        />
                    }
                >
                    파일 업로드
                    <VisuallyHiddenInput
                        ref={fileInputRef}
                        type="file"
                        onChange={(event) => {
                            const file = event.target.files[0];
                            if (!file) {
                                return;
                            }
                            readXLSXFile(file);
                            setIsModified(true);
                            setIsSaved(false);
                            fileInputRef.current.value = "";
                        }}
                    />
                </Button>
            </Box>
            <SuccessModal
                successTitle={successTitle}
                successMsg={successMessage}
                open={openSuccessModal}
                handleClose={handleSuccessModalClose}
            />
            <SaveModal
                type="record"
                recordAddCount={saveCount[0]}
                recordUpdateCount={saveCount[1]}
                itemAddCount={saveCount[2]}
                itemUpdateCount={saveCount[3]}
                itemDeleteCount={saveCount[4]}
                open={openSaveModal}
                handleClose={handleSaveModalClose}
            />
            <NoSaveRequiredModal
                open={openNoSaveRequiredModal}
                handleClose={handleNoSaveRequiredModalClose}
            />
        </>
    );
}

export default ServiceToolbar;
