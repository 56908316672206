const gridLocaleConfig = {
    columnsManagementSearchTitle: "검색",
    columnsManagementNoColumns: "검색된 열 없음",
    columnsManagementShowHideAllText: "모두 보이기/숨기기",
    columnsManagementReset: "초기화",
    columnMenuLabel: "메뉴",
    columnMenuFilter: "필터링",
    columnMenuHideColumn: "해당 열 숨기기",
    columnMenuManageColumns: "열 목록 관리",
    columnMenuUnsort: "정렬 해제",
    columnMenuSortAsc: "오름차순 정렬",
    columnMenuSortDesc: "내림차순 정렬",
    columnHeaderSortIconLabel: "정렬",
    detailPanelToggle: "라인 아이템 토글 버튼",
    footerRowSelected: (count) => `${count}개의 데이터가 선택되었습니다.`,
    filterPanelAddFilter: "필터 추가",
    filterPanelDeleteIconLabel: "제거",
    filterPanelRemoveAll: "필터 모두 제거",
    filterPanelInputLabel: "값",
    filterPanelInputPlaceholder: "필터링할 값",
    filterPanelOperator: "연산자",
    filterPanelColumns: "열",
    filterOperatorContains: "~을 포함하는",
    filterOperatorDoesNotContain: "~을 포함하지 않는",
    filterOperatorEquals: "~와 일치하는",
    filterOperatorDoesNotEqual: "~와 일치하지 않는",
    filterOperatorStartsWith: "~로 시작하는",
    filterOperatorEndsWith: "~로 끝나는",
    filterOperatorIsEmpty: "빈 값",
    filterOperatorIsNotEmpty: "빈 값 아님",
    filterOperatorIsAnyOf: "~ 중 하나",
    footerTotalRows: "총 데이터 수:",
    MuiTablePagination: {
        labelRowsPerPage: "페이지 당 확인할 데이터 수",
        labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / 전체 ${count}`,
    },
    noRowsLabel: "표시할 데이터가 없습니다.",
    noResultsOverlayLabel: "검색 결과가 없습니다.",
    pinToLeft: "왼쪽에 고정",
    pinToRight: "오른쪽에 고정",
    unpin: "고정 해제",
};

export default gridLocaleConfig;
