import {
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import PropTypes from "prop-types";
import generateRandomId from "@utils/format";

function SuccessModal({ successTitle, successMsg, handleClose, open }) {
    return (
        <Dialog open={open} onClose={handleClose} closeAfterTransition={false}>
            <Box display="flex" alignItems="center" sx={{ pt: 1, px: 3 }}>
                <CheckCircleOutline
                    fontSize="large"
                    sx={{
                        path: {
                            color: (theme) => theme.palette.success.main,
                        },
                    }}
                />
                <DialogTitle color="success" sx={{ fontSize: "1.25em", fontWeight: 600 }}>
                    {successTitle}
                </DialogTitle>
            </Box>
            <DialogContent>
                <DialogContentText
                    sx={{
                        maxWidth: "25em",
                        span: {
                            color: (theme) => theme.palette.grey[700],
                        },
                    }}
                >
                    {successMsg.split("<br/>").map((line) => (
                        <span key={generateRandomId()}>
                            {line}
                            <br />
                        </span>
                    ))}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", pb: 3, px: 3 }}>
                <Button
                    color="success"
                    variant="contained"
                    onClick={handleClose}
                    sx={{
                        fontWeight: 500,
                        fontSize: "1em",
                        width: "40%",
                    }}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
}

SuccessModal.propTypes = {
    successTitle: PropTypes.string.isRequired,
    successMsg: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default SuccessModal;
