import {
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
} from "@mui/material";
import { Add, Edit, Delete, Save } from "@mui/icons-material";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------------------------------------

// Save Modal Component
function SaveModal({
    type,
    recordAddCount = 0,
    recordUpdateCount = 0,
    itemAddCount = 0,
    itemUpdateCount = 0,
    itemDeleteCount = 0,
    handleClose,
    open,
}) {
    return (
        <Dialog open={open} closeAfterTransition={false}>
            <Box display="flex" alignItems="center" sx={{ pt: 1, px: 3 }}>
                <Save
                    fontSize="large"
                    sx={{
                        path: {
                            color: (theme) => theme.palette.primary.main,
                        },
                    }}
                />
                <DialogTitle color="primary" sx={{ fontSize: "1.25em", fontWeight: 600 }}>
                    {type === "lineitem" ? "라인 아이템 저장 완료" : "데이터 저장 완료"}
                </DialogTitle>
            </Box>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                <DialogContentText
                    sx={{
                        maxWidth: "25em",
                        span: {
                            color: (theme) => theme.palette.grey[700],
                        },
                    }}
                >
                    다음과 같이 수정하신 내용이 정상적으로 저장되었어요.
                </DialogContentText>
                <Divider />
                <Box sx={{ display: "flex" }}>
                    {type === "record" && (
                        <List
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                minWidth: 200,
                            }}
                        >
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            backgroundColor: (theme) =>
                                                theme.palette.secondary.main,
                                        }}
                                    >
                                        <Add
                                            sx={{
                                                path: {
                                                    color: "white",
                                                },
                                            }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="레코드 생성"
                                    primaryTypographyProps={{
                                        sx: {
                                            fontSize: "0.9em",
                                            color: (theme) => theme.palette.grey[700],
                                        },
                                    }}
                                    secondary={`${recordAddCount}건`}
                                    secondaryTypographyProps={{
                                        sx: {
                                            fontWeight: "700",
                                            fontSize: "1.1em",
                                            color: (theme) => theme.palette.secondary.main,
                                        },
                                    }}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{
                                            backgroundColor: (theme) =>
                                                theme.palette.secondary.main,
                                        }}
                                    >
                                        <Edit
                                            sx={{
                                                path: {
                                                    color: "white",
                                                },
                                            }}
                                        />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="레코드 수정"
                                    primaryTypographyProps={{
                                        sx: {
                                            fontSize: "0.9em",
                                            color: (theme) => theme.palette.grey[700],
                                        },
                                    }}
                                    secondary={`${recordUpdateCount}건`}
                                    secondaryTypographyProps={{
                                        sx: {
                                            fontWeight: "700",
                                            fontSize: "1.1em",
                                            color: (theme) => theme.palette.secondary.main,
                                        },
                                    }}
                                />
                            </ListItem>
                        </List>
                    )}
                    <List
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            minWidth: 200,
                        }}
                    >
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                    }}
                                >
                                    <Add
                                        sx={{
                                            path: {
                                                color: "white",
                                            },
                                        }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="라인 아이템 생성"
                                primaryTypographyProps={{
                                    sx: {
                                        fontSize: "0.9em",
                                        color: (theme) => theme.palette.grey[700],
                                    },
                                }}
                                secondary={`${itemAddCount}건`}
                                secondaryTypographyProps={{
                                    sx: {
                                        fontWeight: "700",
                                        fontSize: "1.1em",
                                        color: (theme) => theme.palette.primary.main,
                                    },
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                    }}
                                >
                                    <Edit
                                        sx={{
                                            path: {
                                                color: "white",
                                            },
                                        }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="라인 아이템 수정"
                                primaryTypographyProps={{
                                    sx: {
                                        fontSize: "0.9em",
                                        color: (theme) => theme.palette.grey[700],
                                    },
                                }}
                                secondary={`${itemUpdateCount}건`}
                                secondaryTypographyProps={{
                                    sx: {
                                        fontWeight: "700",
                                        fontSize: "1.1em",
                                        color: (theme) => theme.palette.primary.main,
                                    },
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                    }}
                                >
                                    <Delete
                                        sx={{
                                            path: {
                                                color: "white",
                                            },
                                        }}
                                    />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary="라인 아이템 삭제"
                                primaryTypographyProps={{
                                    sx: {
                                        fontSize: "0.9em",
                                        color: (theme) => theme.palette.grey[700],
                                    },
                                }}
                                secondary={`${itemDeleteCount}건`}
                                secondaryTypographyProps={{
                                    sx: {
                                        fontWeight: "700",
                                        fontSize: "1.1em",
                                        color: (theme) => theme.palette.primary.main,
                                    },
                                }}
                            />
                        </ListItem>
                    </List>
                </Box>
                <Divider />
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", pb: 3, px: 3 }}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleClose}
                    sx={{
                        fontWeight: 500,
                        fontSize: "1em",
                        width: "40%",
                        maxWidth: "150px",
                    }}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// ----------------------------------------------------------------------------------------------------

SaveModal.propTypes = {
    type: PropTypes.string.isRequired,
    recordAddCount: PropTypes.number.isRequired,
    recordUpdateCount: PropTypes.number.isRequired,
    itemAddCount: PropTypes.number.isRequired,
    itemUpdateCount: PropTypes.number.isRequired,
    itemDeleteCount: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

// ----------------------------------------------------------------------------------------------------

export default SaveModal;
