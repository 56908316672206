import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useProjectStore, useTableStore } from "@stores/useSheetStore";

// ----------------------------------------------------------------------------------------------------

// SheetSelect Component
function SheetSelect() {
    const { selectedProject, projectMap, setSelectedProject } = useProjectStore();
    const { selectedTable, tableList, setSelectedTable } = useTableStore();

    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
        setSelectedTable("");
    };

    const handleTableChange = (event) => {
        setSelectedTable(event.target.value);
    };

    return (
        <Box sx={{ display: "flex", gap: 2 }}>
            <FormControl sx={{ width: 200 }}>
                <InputLabel id="project-select-label">프로젝트</InputLabel>
                <Select
                    labelId="project-select-label"
                    id="project-select"
                    value={selectedProject}
                    label="프로젝트"
                    onChange={handleProjectChange}
                >
                    {Object.entries(projectMap).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{ width: 200 }} disabled={!selectedProject}>
                <InputLabel id="table-select-label">테이블</InputLabel>
                <Select
                    labelId="table-select-label"
                    id="table-select"
                    value={selectedTable}
                    label="테이블"
                    onChange={handleTableChange}
                >
                    {tableList &&
                        tableList.map((value) => (
                            <MenuItem key={value} value={value}>
                                {value}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Box>
    );
}

// ----------------------------------------------------------------------------------------------------

export default SheetSelect;
