import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import router from "@routes/router";
import { RouterProvider } from "react-router-dom";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}>
            <App />
        </RouterProvider>
    </React.StrictMode>,
);
