import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const options = {
    withCredentials: true,
};

// Get Project Map
export const getProjectList = async () => {
    try {
        const response = await axios.get(`${API_URL}/sheet/getProjectList`, options);
        return response.data;
    } catch (error) {
        throw error.response;
    }
};

// Get Table List
export const getTableList = async (project) => {
    try {
        const response = await axios.post(`${API_URL}/sheet/getTableList`, project, options);
        return response.data;
    } catch (error) {
        throw error.response;
    }
};

// Get Data Label
export const getDataLabel = async (dataElements) => {
    try {
        const response = await axios.post(`${API_URL}/sheet/getDataLabel`, dataElements, options);
        return response.data;
    } catch (error) {
        throw error.response;
    }
};

// Get Table Data
export const getTableData = async (selectedData) => {
    try {
        const response = await axios.post(`${API_URL}/sheet/getData`, selectedData, options);
        return response.data;
    } catch (error) {
        throw error.response;
    }
};

// Get Line Item Data
export const getLineItems = async (selectedData) => {
    try {
        const response = await axios.post(`${API_URL}/sheet/getLineItems`, selectedData, options);
        return response.data;
    } catch (error) {
        throw error.response;
    }
};

// Update Table Data
export const updateTableData = async (updatedData) => {
    try {
        const response = await axios.post(`${API_URL}/sheet/updateData`, updatedData, options);
        return response.data;
    } catch (error) {
        throw error.response;
    }
};
