// Function for Generating Random ID
function generateRandomId() {
    const length = 16;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomValue = "";

    for (let i = 0, n = charset.length; i < length; i += 1) {
        randomValue += charset.charAt(Math.floor(Math.random() * n));
    }

    return randomValue;
}

export default generateRandomId;
