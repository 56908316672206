import { Add, CompareArrows, Delete } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { GridRowModes, GridToolbarContainer } from "@mui/x-data-grid-pro";
import generateRandomId from "@utils/format";
import { ServiceToolbar } from "@components/toolbar";
import PropTypes from "prop-types";
import { useDataStore } from "@stores/useSheetStore";

// ----------------------------------------------------------------------------------------------------

// Line Item Grid Toolbar Component
function LineItemGridToolbar({ setRowModesModel, resizeColumns }) {
    const { rowData, rowSelectionModel, setIsModified, setRowData, setRowSelectionModel } =
        useDataStore();

    const addLineItem = () => {
        const id = generateRandomId();
        const emptyRow = Object.keys(rowData._bp_lineitems[0])
            .filter((key) => key !== "id")
            .reduce(
                (acc, key) => {
                    acc[key] = "";
                    return acc;
                },
                { id },
            );

        const newRowData = {
            ...rowData,
            _bp_lineitems: [emptyRow, ...rowData._bp_lineitems],
        };

        setRowData(newRowData);
        setRowModesModel((prevModel) => ({
            ...prevModel,
            [id]: { mode: GridRowModes.Edit },
        }));
    };

    const deleteLineItems = () => {
        const selectedIDs = new Set(rowSelectionModel);

        const newRowData = {
            ...rowData,
            _bp_lineitems: rowData._bp_lineitems.filter((item) => !selectedIDs.has(item.id)),
        };

        setRowData(newRowData);
        setRowSelectionModel([]);
        setIsModified(true);
    };

    return (
        <GridToolbarContainer
            sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: 1.5,
                paddingBottom: 1,
                px: 3,
                backgroundColor: (theme) => theme.palette.primary.extraLight,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    gap: 1,
                }}
            >
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!rowData._bp_lineitems || rowData._bp_lineitems.length === 0}
                    startIcon={
                        <Add
                            sx={{
                                path: {
                                    color: (theme) =>
                                        !rowData._bp_lineitems || rowData._bp_lineitems.length === 0
                                            ? theme.palette.action.disabled
                                            : "white",
                                },
                            }}
                        />
                    }
                    onClick={addLineItem}
                    sx={{
                        fontWeight: 700,
                        fontSize: "1rem",
                        borderRadius: "10px",
                        minWidth: "100px",
                        height: "40px",
                    }}
                >
                    추가
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!rowData._bp_lineitems || rowData._bp_lineitems.length === 0}
                    startIcon={
                        <CompareArrows
                            sx={{
                                path: {
                                    color: (theme) =>
                                        !rowData._bp_lineitems || rowData._bp_lineitems.length === 0
                                            ? theme.palette.action.disabled
                                            : "white",
                                },
                            }}
                        />
                    }
                    onClick={resizeColumns}
                    sx={{
                        fontWeight: 700,
                        fontSize: "1rem",
                        borderRadius: "10px",
                        minWidth: "180px",
                        height: "40px",
                    }}
                >
                    열 너비 자동 조정
                </Button>
                <Button
                    sx={{
                        fontWeight: 600,
                        fontSize: "1rem",
                        borderRadius: "10px",
                        minWidth: "130px",
                    }}
                    color="error"
                    disabled={rowSelectionModel.length === 0}
                    type="button"
                    variant="contained"
                    onClick={deleteLineItems}
                    startIcon={
                        <Delete
                            sx={{
                                path: {
                                    color: (theme) =>
                                        rowSelectionModel.length === 0
                                            ? theme.palette.action.disabled
                                            : "white",
                                },
                            }}
                        />
                    }
                >
                    선택 삭제
                </Button>
            </Box>
            <ServiceToolbar />
        </GridToolbarContainer>
    );
}

// ----------------------------------------------------------------------------------------------------

LineItemGridToolbar.propTypes = {
    setRowModesModel: PropTypes.func.isRequired,
    resizeColumns: PropTypes.func.isRequired,
};

// ----------------------------------------------------------------------------------------------------

export default LineItemGridToolbar;
