import { Box, Button, Card, styled, Typography } from "@mui/material";
import generateRandomId from "@utils/format";
import { getProjectList, getTableList, getDataLabel, getTableData } from "@service/sheet.service";
import { RecordGrid } from "@components/grid";
import SheetSelect from "@components/select";
import sleep from "@utils/time";
import { useDataStore, useProjectStore, useTableStore } from "@stores/useSheetStore";
import { useEffect, useState } from "react";

// ----------------------------------------------------------------------------------------------------

// Sheet Card Component
const SheetCard = styled(Card)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignSelf: "start",
    width: "100%",
    minWidth: "540px",
    height: "90vh",
    padding: theme.spacing(4),
    gap: theme.spacing(2),
    boxShadow:
        "hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px",
    [theme.breakpoints.up("sm")]: {
        width: "80%",
    },
}));

// ----------------------------------------------------------------------------------------------------

// Sheet Page
export default function Sheet() {
    const [allSelected, setAllSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { selectedProject, setSelectedProject, addProjectMap, resetProjectMap } =
        useProjectStore();
    const { selectedTable, setSelectedTable, setTableList } = useTableStore();
    const {
        sheetDataLabel,
        setIsModified,
        setOriginData,
        setSaveData,
        setSheetData,
        setSheetDataLabel,
        resetDataStore,
    } = useDataStore();

    const patchProjectMap = async () => {
        resetProjectMap();
        const response = await getProjectList();
        addProjectMap(response.data);
    };

    const patchTableList = async () => {
        await getTableList({ projectName: selectedProject }).then((response) => {
            setTableList(response.data);
        });
    };

    const getSelectedData = async () => {
        setIsLoading(true);
        resetDataStore();
        await getTableData({ projectName: selectedProject, tableName: selectedTable })
            .then((response) => {
                if (!response.data || response.data.length === 0) {
                    setSheetData([]);
                    return [];
                }

                // Add Random ID Key in Sheet Data
                const identifiedSheetData = response.data.map((record) => {
                    const orderedRecord = {
                        record_no: record.record_no,
                        creator_id: record.creator_id,
                        uuu_creation_date: record.uuu_creation_date,
                        ...record,
                    };

                    return {
                        id: generateRandomId(),
                        ...orderedRecord,
                        _bp_lineitems:
                            record._bp_lineitems?.map((item) => {
                                const orderedItem = {
                                    uuu_tab_id: item.uuu_tab_id,
                                    tab_id: item.tab_id,
                                    LineAutoSeq: item.LineAutoSeq,
                                    ...item,
                                };

                                return {
                                    id: generateRandomId(),
                                    ...orderedItem,
                                };
                            }) || [],
                    };
                });

                return identifiedSheetData;
            })
            .then(async (identifiedSheetData) => {
                if (identifiedSheetData.length === 0) {
                    return;
                }

                // Add Record Data Element
                const dataElements = Object.keys(identifiedSheetData[0]).filter(
                    (key) => !(key in sheetDataLabel),
                );
                const lineItemRecord = identifiedSheetData.find(
                    (record) =>
                        Array.isArray(record._bp_lineitems) && record._bp_lineitems.length > 0,
                );

                // Add Line Item Data Element
                if (lineItemRecord) {
                    const lineItemElements = Object.keys(lineItemRecord._bp_lineitems[0]).filter(
                        (key) => !(key in sheetDataLabel),
                    );
                    dataElements.push(...lineItemElements);
                }

                const response = await getDataLabel({ dataElements });

                if (response.data) {
                    setSheetDataLabel(response.data);
                }

                setOriginData(identifiedSheetData);
                setSaveData(identifiedSheetData);
                setSheetData(identifiedSheetData);
            })
            .then(() => sleep(0));
        setIsLoading(false);
        setIsModified(false);
    };

    useEffect(() => {
        if (selectedProject && selectedTable) {
            setAllSelected(true);
        }
    }, [selectedProject, selectedTable]);

    useEffect(() => {
        if (selectedProject) {
            patchTableList();
            setAllSelected(false);
        }
    }, [selectedProject]);

    useEffect(() => {
        patchProjectMap();
        setSheetData([]);
        setSelectedProject("");
        setSelectedTable("");
    }, []);

    return (
        <SheetCard>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    color="primary"
                    component="h1"
                    variant="h4"
                    sx={{ fontSize: "1.25rem", fontWeight: 700 }}
                >
                    레코드 편집기
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                    <SheetSelect />
                    <Button
                        sx={{
                            fontWeight: 600,
                            fontSize: "1rem",
                            borderRadius: "50px",
                            minWidth: "80px",
                        }}
                        disabled={!allSelected}
                        type="button"
                        variant="outlined"
                        onClick={getSelectedData}
                    >
                        조회
                    </Button>
                </Box>
            </Box>
            <RecordGrid isLoading={isLoading} />
        </SheetCard>
    );
}
